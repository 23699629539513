import React from 'react' 
import { useEffect,useState } from 'react';
import Auth from '../component/Auth'
import {ToastContainer, toast } from 'react-toastify'; 

function Account() {

    
    const handleLogout = () => {
        // Clear local storage and session storage
        localStorage.clear();
        sessionStorage.clear();
        // Redirect to login page
        toast.success('Logout Success');
        setTimeout(() => {
            window.location.href = "/Login"; // Redirect to the login page
          }, 2000)

} 

    return (
        <>
        <Auth/>
            <div className="container bg-n900 min-h-dvh relative overflow-hidden flex justify-start items-start text-white pb-28">
                <div className="" />
                <div className="relative z-20 p-6 w-full">
                    <div className="flex justify-between items-center">
                        <p className="text-2xl font-semibold">Account</p>
                        <div className="flex justify-start items-center gap-3">
                            <div className="p-2 rounded-full flex justify-center items-center bg-n700 text-g300">
                                <i className="ph ph-headset" />
                            </div>
                            <div className="p-2 rounded-full flex justify-center items-center bg-n700 text-g300">
                                <i className="ph ph-bell" />
                            </div>
                        </div>
                    </div>
                    <div className="py-8">
                        <a className="w-full flex justify-between items-center gap-6 bg-white bg-opacity-5 p-4 rounded-xl">
                            <div className="flex justify-start items-center gap-3">
                            <img style={{ width: '48px', height: '48px', borderRadius: '50%' }}  src={localStorage.getItem('userImage')} alt="" />
                                <div className="">
                                    <p className="font-semibold">{localStorage.getItem('userName')}</p>
                                    <p className="text-n70 text-sm">{localStorage.getItem('userEmail')}</p>
                                </div>
                            </div>
                            <i className="ph ph-caret-right text-g300" />
                        </a>
                    </div>
                    <div className="">
                        <p className="text-n70 font-semibold pb-5">Wallet</p>
                        <div className="flex flex-col gap-5">
                            <a
                                href="/sent"
                                className="rounded-lg p-3 border border-white border-opacity-5 flex justify-between items-center hover:bg-white hover:bg-opacity-5 duration-300 group"
                            >
                                <div className="flex justify-start items-center gap-3">
                                    <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                        <i className="ph ph-paper-plane-tilt" />
                                    </div>
                                    <p className="font-semibold">Send</p>
                                </div>
                                <i className="ph ph-caret-right text-g300" />
                            </a>
                            <a
                                href="/received"
                                className="rounded-lg p-3 border border-white border-opacity-5 flex justify-between items-center hover:bg-white hover:bg-opacity-5 duration-300 group"
                            >
                                <div className="flex justify-start items-center gap-3">
                                    <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                        <i className="ph ph-download-simple" />
                                    </div>
                                    <p className="font-semibold">Receive</p>
                                </div>
                                <i className="ph ph-caret-right text-g300" />
                            </a>
                            <a
                                href="/swap"
                                className="rounded-lg p-3 border border-white border-opacity-5 flex justify-between items-center hover:bg-white hover:bg-opacity-5 duration-300 group"
                            >
                                <div className="flex justify-start items-center gap-3">
                                    <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                        <i className="ph ph-swap" />
                                    </div>
                                    <p className="font-semibold">Swap</p>
                                </div>
                                <i className="ph ph-caret-right text-g300" />
                            </a>
                            <a
                                href="/buy-sell"
                                className="rounded-lg p-3 border border-white border-opacity-5 flex justify-between items-center hover:bg-white hover:bg-opacity-5 duration-300 group"
                            >
                                <div className="flex justify-start items-center gap-3">
                                    <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                        <i className="ph ph-text-strikethrough" />
                                    </div>
                                    <p className="font-semibold">Buy &amp; Sell Crypto</p>
                                </div>
                                <i className="ph ph-caret-right text-g300" />
                            </a>
                            <a
                                href="#"
                                className="rounded-lg p-3 border border-white border-opacity-5 flex justify-between items-center hover:bg-white hover:bg-opacity-5 duration-300 group"
                            >
                                <div className="flex justify-start items-center gap-3">
                                    <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                        <i className="ph ph-gift" />
                                    </div>
                                    <p className="font-semibold">Rewards</p>
                                </div>
                                <i className="ph ph-caret-right text-g300" />
                            </a>
                            <a
                                href="#"
                                className="rounded-lg p-3 border border-white border-opacity-5 flex justify-between items-center hover:bg-white hover:bg-opacity-5 duration-300 group"
                            >
                                <div className="flex justify-start items-center gap-3">
                                    <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                        <i className="ph ph-hand-coins" />
                                    </div>
                                    <p className="font-semibold">Pay</p>
                                </div>
                                <i className="ph ph-caret-right text-g300" />
                            </a>
                           
                            
                        </div>
                    </div>
                    <div className="pt-8">
                        <p className="text-n70 font-semibold pb-5">User Experience</p>
                        <div className="flex flex-col gap-5">
                            <a className="rounded-lg p-3 border border-white border-opacity-5 flex justify-between items-center hover:bg-white hover:bg-opacity-5 duration-300 group">
                                <div className="flex justify-start items-center gap-3">
                                    <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                        <i className="ph ph-gift" />
                                    </div>
                                    <p className="font-semibold">Feature Suggestion</p>
                                </div>
                                <div className="flex justify-start items-center gap-2">
                                    <p className="text-n70 text-sm">English</p>
                                    <i className="ph ph-caret-right text-g300" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="pt-8">
                        <p className="text-n70 font-semibold pb-5">Support</p>
                        <div className="flex flex-col gap-5">
                            <a
                                href="#"
                                className="rounded-lg p-3 border border-white border-opacity-5 flex justify-between items-center hover:bg-white hover:bg-opacity-5 duration-300 group"
                            >
                                <div className="flex justify-start items-center gap-3">
                                    <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                        <i className="ph ph-question" />
                                    </div>
                                    <p className="font-semibold">Help Center</p>
                                </div>
                                <i className="ph ph-caret-right text-g300" />
                            </a>
                            <a
                                href="/privacy-policy"
                                className="rounded-lg p-3 border border-white border-opacity-5 flex justify-between items-center hover:bg-white hover:bg-opacity-5 duration-300 group"
                            >
                                <div className="flex justify-start items-center gap-3">
                                    <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                        <i className="ph ph-file-lock" />
                                    </div>
                                    <p className="font-semibold">Privacy Policy</p>
                                </div>
                                <i className="ph ph-caret-right text-g300" />
                            </a>
                            <a
                                href="/about"
                                className="rounded-lg p-3 border border-white border-opacity-5 flex justify-between items-center hover:bg-white hover:bg-opacity-5 duration-300 group"
                            >
                                <div className="flex justify-start items-center gap-3">
                                    <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                        <i className="ph ph-paper-plane-tilt" />
                                    </div>
                                    <p className="font-semibold">About</p>
                                </div>
                                <i className="ph ph-caret-right text-g300" />
                            </a>
                        </div>
                    </div>
                    <div className="pt-8">
                        <p className="text-n70 font-semibold pb-5">Social</p>
                        <div className="flex flex-col gap-5">
                            <a href="https://t.me/kaymcoindigitalasset" target="blank" className="rounded-lg p-3 border border-white border-opacity-5 flex justify-between items-center hover:bg-white hover:bg-opacity-5 duration-300 group">
                                <div className="flex justify-start items-center gap-3">
                                    <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                        <i className="ph ph-telegram-logo" />
                                    </div>
                                    <p className="font-semibold">Telegram</p>
                                </div>
                                <i className="ph ph-caret-right text-g300" />
                            </a>
                            <a href="https://www.youtube.com/channel/UCd39yTqnDn2DbFwC01lp3uw" target="blank" className="rounded-lg p-3 border border-white border-opacity-5 flex justify-between items-center hover:bg-white hover:bg-opacity-5 duration-300 group">
                                <div className="flex justify-start items-center gap-3">
                                    <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                        <i className="ph ph-youtube-logo" />
                                    </div>
                                    <p className="font-semibold">YouTube</p>
                                </div>
                                <i className="ph ph-caret-right text-g300" />
                            </a>
                            <a className="rounded-lg p-3 border border-white border-opacity-5 flex justify-between items-center hover:bg-white hover:bg-opacity-5 duration-300 group">
                                <div className="flex justify-start items-center gap-3">
                                    <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                        <i className="ph ph-linkedin-logo" />
                                    </div>
                                    <p className="font-semibold">LinkedIn</p>
                                </div>
                                <i className="ph ph-caret-right text-g300" />
                            </a>
                            <a href="https://www.facebook.com/kaymcoin" target="blank" className="rounded-lg p-3 border border-white border-opacity-5 flex justify-between items-center hover:bg-white hover:bg-opacity-5 duration-300 group">
                                <div className="flex justify-start items-center gap-3">
                                    <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                        <i className="ph ph-facebook-logo" />
                                    </div>
                                    <p className="font-semibold">Facebook</p>
                                </div>
                                <i className="ph ph-caret-right text-g300" />
                            </a>
                            <a href="https://www.instagram.com/kaymcoin/" target="blank" className="rounded-lg p-3 border border-white border-opacity-5 flex justify-between items-center hover:bg-white hover:bg-opacity-5 duration-300 group">
                                <div className="flex justify-start items-center gap-3">
                                    <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                        <i className="ph ph-instagram-logo" />
                                    </div>
                                    <p className="font-semibold">Instagram</p>
                                </div>
                                <i className="ph ph-caret-right text-g300" />
                            </a>
                            <a href="https://x.com/kaymcoin" target="blank" className="rounded-lg p-3 border border-white border-opacity-5 flex justify-between items-center hover:bg-white hover:bg-opacity-5 duration-300 group">
                                <div className="flex justify-start items-center gap-3">
                                    <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                        <i className="ph ph-twitter-logo" />
                                    </div>
                                    <p className="font-semibold">Twitter</p>
                                </div>
                                <i className="ph ph-caret-right text-g300" />
                            </a>
                            <a className="rounded-lg p-3 border border-white border-opacity-5 flex justify-between items-center hover:bg-white hover:bg-opacity-5 duration-300 group">
                                <div className="flex justify-start items-center gap-3">
                                    <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                        <i className="ph ph-discord-logo" />
                                    </div>
                                    <p className="font-semibold">Discord</p>
                                </div>
                                <i className="ph ph-caret-right text-g300" />
                            </a>
                            <a className="rounded-lg p-3 border border-white border-opacity-5 flex justify-between items-center hover:bg-white hover:bg-opacity-5 duration-300 group">
                                <div className="flex justify-start items-center gap-3">
                                    <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                        <i className="ph ph-bookmark-simple" />
                                    </div>
                                    <p className="font-semibold">Newsletter</p>
                                </div>
                                <i className="ph ph-caret-right text-g300" />
                            </a>
                            <button className="rounded-lg p-3 border border-white border-opacity-5 flex justify-between items-center hover:bg-white hover:bg-opacity-5 duration-300 group logoutModalOpenButton " onClick={handleLogout}>
                                <div className="flex justify-start items-center gap-3">
                                    <div className="p-2.5 bg-white bg-opacity-5 flex justify-center items-center rounded-full text-g300 text-xl group-hover:bg-g300 group-hover:text-white group-hover:bg-opacity-100 duration-300">
                                        <i className="ph ph-arrows-down-up" />
                                    </div>
                                    <p className="font-semibold">Logout</p>
                                </div>
                            </button>
                        </div>
                    </div>
                    {/* Tab Area Start */}
                    <div className="fixed left-0 right-0 bottom-0">
                        <div className="container relative bg-white bg-opacity-5 py-5 flex justify-around items-center after:absolute after:bg-n700 after:inset-0">
                            <div className="absolute left-[41%] bottom-[72px] z-40">
                                <a
                                    href="#"
                                    className="bg-g300 text-2xl p-3.5 rounded-full flex justify-center items-center relative"
                                >
                                    <i className="ph ph-arrows-counter-clockwise" />
                                    <div className="absolute -bottom-2 -left-5 -right-5 -z-10">
                                        <img src="assets/images/reload-bg.png" alt="" />
                                    </div>
                                </a>
                            </div>
                            <a
                                href="/"
                                className="flex flex-col justify-center items-center gap-1 relative z-20"
                            >
                                <i className="ph ph-house text-2xl" />
                                <p className="text-xs font-semibold">Home</p>
                            </a>
                            <a
                                href="/browser"
                                className="flex flex-col justify-center items-center gap-1 z-20"
                            >
                                <i className="ph ph-globe text-2xl" />
                                <p className="text-xs font-semibold">Browser</p>
                            </a>
                            <a
                                href="/marketplace"
                                className="flex flex-col justify-center items-center gap-1 z-20"
                            >
                                <i className="ph ph-align-bottom text-2xl" />
                                <p className="text-xs font-semibold">Marketplace</p>
                            </a>
                            <a
                                href="/account"
                                className="flex flex-col justify-center items-center gap-1 z-20 text-g300"
                            >
                                <i className="ph ph-user text-2xl" />
                                <p className="text-xs font-semibold">Account</p>
                            </a>
                        </div>
                    </div>
                    {/* Tab Area End */}
                </div>
                <div className="hidden inset-0 z-40 checkBiometrics">
                    <div className="container bg-white bg-opacity-10 flex justify-end items-end h-full">
                        <div className="bg-n900 px-6 pt-3 pb-6 w-full rounded-t-[31px] relative">
                            <div className="flex justify-center items-center">
                                <div className="w-16 h-1 bg-white bg-opacity-5 rounded-full" />
                            </div>
                            <h2 className="text-xl font-semibold text-center pt-8">
                                Check your biometrics
                            </h2>
                            <button className="absolute top-4 right-4 text-2xl text-g300 checkBiometricsCloseButton">
                                <i className="ph ph-x-circle" />
                            </button>
                            <div className="flex flex-col gap-4 pt-8">
                                <div className="text-[100px] text-g300 flex justify-center items-center">
                                    <i className="ph ph-fingerprint" />
                                </div>
                                <div className="text-center">
                                    <p className="text-xs text-n70">Touch the fingerprint sensor</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default Account