import './App.css';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Browser from './Pages/Browser';
import Marketplace from './Pages/Marketplace';
import Account from './Pages/Account';
import Notification from './Pages/Notification';
import Sent from './Pages/Sent';
import Received from './Pages/Received';
import ReceivedAmount from './Pages/ReceivedAmount';
import SuccessfullySent from './Pages/SuccessfullySent';
import Swap from './Pages/Swap';
import BuySell from './Pages/BuySell';
import AddToken from './Pages/AddToken';
import TopGainers from './Pages/TopGainers';
import TopLosers from './Pages/TopLosers';
import SendTo from './Pages/SendTo';
import InputAmount from './Pages/InputAmount';
import About from './Pages/About';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import AutoLock from './Pages/AutoLock';
import SecureWallet from './Pages/SecureWallet';
import WriteSeedPhrase from './Pages/WriteSeedPhrase';
import ConfirmSeedList from './Pages/ConfirmSeedList';
import ChooseAccount from './Pages/ChooseAccount';
import BackupWallet from './Pages/BackupWallet';
import Currency from './Pages/Currency';
import Language from './Language';
import CustomNetwork from './CustomNetwork';
import AddressBook from './AddressBook';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Txnhistory from './Pages/Txnhistory';
import Authentication from './Pages/Authenctication';


function App() {
  return (
    <>
    <BrowserRouter>
    <Routes>
      <Route excat path='/' element={<Home/>}/>
      <Route path='/Browser' element={<Browser/>}/>
      <Route path='/marketplace' element={<Marketplace/>}/>
      <Route path='/Account' element={<Account/>}></Route>
      <Route path='/notification' element={<Notification/>}></Route>
      <Route path="/sent" element={<Sent />} />
      <Route path='/received' element={<Received/>}></Route>
      <Route path='/received-amount' element={<ReceivedAmount/>}></Route>
      <Route path='/successfully-sent' element={<SuccessfullySent/>}></Route>
      <Route path='/swap' element={<Swap/>}></Route>
      <Route path='/buy-sell' element={<BuySell/>}></Route>
      <Route path='/add-token' element={<AddToken/>}></Route>
      <Route path='/top-gainers' element={<TopGainers/>}></Route>
      <Route path='/top-losers' element={<TopLosers/>}></Route>
      <Route path='/send-to' element={<SendTo/>}></Route>
      <Route path='/input-amount' element={<InputAmount/>}></Route>
      <Route path='/about' element={<About/>}></Route>
      <Route path='/privacy-policy' element={<PrivacyPolicy/>}></Route>
      <Route path='/auto-lock' element={<AutoLock/>}></Route>
      <Route path='/secure-wallet' element={<SecureWallet/>}></Route>
      <Route path='/write-seed-phrase' element={<WriteSeedPhrase/>}></Route>
      <Route path='/confirm-seed-list' element={<ConfirmSeedList/>}></Route>
      <Route path='/choose-account' element={<ChooseAccount/>}></Route>
      <Route path='/backup-wallet' element={<BackupWallet/>}></Route>
      <Route path='/currency' element={<Currency/>}></Route>
      <Route path='/language' element={<Language/>}></Route>
      <Route path='/custom-network' element={<CustomNetwork/>}></Route>
      <Route path='/address-book' element={<AddressBook/>}></Route>
      <Route path='/Login' element={<Login/>}></Route>
      <Route path='/Register' element={<Register/>}></Route>
      <Route path='/Txnhistory' element={<Txnhistory/>}></Route>
      <Route path='/Authentication' element={<Authentication/>}></Route>

      

      


    

    </Routes>
    
    </BrowserRouter>
    </>
  );
}

export default App;