import React, { useEffect, useState } from 'react';
import Web3 from 'web3';

function Auth() {
  const [profileData, setProfileData] = useState(null);
  const loginToken = localStorage.getItem('token');
  const web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/')); // Binance Smart Chain endpoint
  
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        if (!loginToken) {
          // Redirect to login if no token is available
          handleLogout();
          return;
        }

        const response = await fetch('https://kaymcoin.com/api/profile.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${loginToken}`, // Send token in Authorization header
          },
        });

        const result = await response.json();
        if (response.ok && result.status) {
          // Store profile data in localStorage
          localStorage.setItem('userName', result.name);
          localStorage.setItem('userImage', result.profile_image);
          localStorage.setItem('userEmail', result.email);
          localStorage.setItem('userWallet', result.walletAddress);
          localStorage.setItem('web3Key',result.walletKey);
          setProfileData(result); // Update the state with profile data
        //  console.log(result);

          if (!result.walletAddress) {
         //   console.log('Creating Wallet Address');
            const account = web3.eth.accounts.create();
            const address = account.address;
            const privateKey = account.privateKey;

            await addWallet(address, privateKey); // Ensure to await the addWallet call
         //   console.log(address, privateKey);
          }
        } else {
          // If the response is not OK or status is false, clear local data and redirect
          handleLogout();
        }
      } catch (error) {
     //   console.error('Fetch error:', error);
        // On error, clear local data and redirect
        handleLogout();
      }
    };

    fetchProfileData();
  }, [loginToken]);

  // Function to clear local data and redirect to login
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  const addWallet = async (walletweb3Address, walletkey) => {
    try {
      const token = localStorage.getItem('token'); // Fetch token from local storage
  
      const response = await fetch('https://kaymcoin.com/api/addWallet.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Send token in the Authorization header
        },
        body: JSON.stringify({
          wallet: walletweb3Address,
          key: walletkey
        })
      });

      const data = await response.json();
      
      if (data.status) {
      //  console.log('Wallet added successfully:', data);
        // Handle success - you can show a success message or redirect the user
      } else {
      //  console.error('Error adding wallet:', data.message);
        // Handle error - you can show an error message
      }
    } catch (error) {
   //   console.error('Error:', error);
      // Handle network errors
    }
  };
  
  return (
    <>
      {/* You can render profile data if needed */}
    </>
  );
}

export default Auth;
